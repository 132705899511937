import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  value: {
    lang: string
  }
  children: React.ReactNode
}

const PageContext = React.createContext({
  lang: 'en'
})

export const PageContextProvider = ({ value, children }: Props) => {
  const { i18n } = useTranslation()

  i18n.changeLanguage(value.lang)

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
