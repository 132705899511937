import React from 'react'
import type { GatsbySSR, RenderBodyArgs } from 'gatsby'
import { I18nextProvider } from 'react-i18next'
import i18n from './src/i18n/configs'
import { PageContextProvider } from './src/i18n/PageContext'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props
}) => {
  return (
    <PageContextProvider value={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

const headComponents = [
  <link
    key={0}
    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
  />,
  <link
    key={1}
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
  />,
  <link
    key={2}
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/katex@0.16.4/dist/katex.min.css"
    integrity="sha384-vKruj+a13U8yHIkAyGgK1J3ArTLzrFGBbBc0tDp4ad/EyewESeXE/Iv67Aj8gKZ0"
    crossOrigin="anonymous"
  />,
  <script
    key={3}
    defer
    src="https://cdn.jsdelivr.net/npm/katex@0.16.4/dist/katex.min.js"
    integrity="sha384-PwRUT/YqbnEjkZO0zZxNqcxACrXe+j766U2amXcgMg5457rve2Y7I6ZJSm2A0mS4"
    crossOrigin="anonymous"
  ></script>
]

export const onRenderBody = ({
  pathname,
  setHeadComponents,
  setHtmlAttributes
}: RenderBodyArgs) => {
  setHeadComponents(headComponents)

  const lang = pathname.split('/')[1]

  setHtmlAttributes({ lang: lang })
}
