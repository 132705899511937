module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_graphql@16.7.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c777fa8c5206eefd98db7e6dae16f615"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.11.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-D2YLVGJTXW"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.1.6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
